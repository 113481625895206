<template>
  <PopUp :show="modal" @hideModal="hideModal">
    <ValidationObserver
      v-slot="{ invalid }"
      ref="observer"
      tag="div"
      class="publication"
      id="scroll-block"
    >
      <header class="publication__header">
        <h3 class="publication__header_title">{{ publication.id ? "Edit" : "Add" }} Publication</h3>
        <div class="publication__header_actions">
          <BaseButton
            v-if="item"
            class="button--sm button--outline button--uppercase"
            text="Delete Item"
            @click="$emit('removePublication', publication)"
          ></BaseButton>
          <BaseButton
            class="button--sm button--green button--uppercase"
            text="Save Changes"
            :loading="changesLoading"
            :disabled="invalid || !changed"
            @click="savePublication"
          ></BaseButton>
        </div>
      </header>
      <div class="form__row">
        <ValidationProvider
          tag="div"
          class="form__row_col"
          :rules="{ required: true, min: 2, max: 255 }"
          v-slot="{ errors }"
        >
          <BaseInput
            placeholder="Title"
            :required="true"
            :validateError="errors[0]"
            v-model="publication.title"
            :element="publication.title"
            @input="publication.title = $event"
          ></BaseInput>
        </ValidationProvider>
      </div>
      <div class="form__row">
        <ValidationProvider
          tag="div"
          class="form__row_col form__row_col--side"
          :rules="{ required: true, min: 2, max: 255 }"
          v-slot="{ errors }"
        >
          <BaseInput
            placeholder="Type"
            :required="true"
            :validateError="errors[0]"
            v-model="publication.type"
            :element="publication.type"
            @input="publication.type = $event"
          ></BaseInput>
        </ValidationProvider>
        <ValidationProvider
          tag="div"
          class="form__row_col form__row_col--side"
          :rules="{ required: true, min: 2, max: 255 }"
          v-slot="{ errors }"
        >
          <BaseInput
            placeholder="Author"
            :required="true"
            :validateError="errors[0]"
            v-model="publication.author"
            :element="publication.author"
            @input="publication.author = $event"
          ></BaseInput>
        </ValidationProvider>
      </div>
      <ValidationProvider
        tag="div"
        class="form__row"
        :rules="{ required: true }"
        v-slot="{ errors }"
      >
        <MultipleAutocompleteInput
          id="autocomplete-category"
          :class="{ 'autocomplete-input--invalid': errors[0] }"
          v-model="publication.artists"
          :creating="false"
          :focusOpen="true"
          :required="true"
          :validateError="errors[0]"
          :loading="artistsLoading"
          :items="publication.artists"
          :matches="artists"
          placeholder="Artists"
          @input="publication.artists = $event"
          @searchMatches="apiGetSearchedArtists"
        ></MultipleAutocompleteInput>
      </ValidationProvider>
      <div class="form__row">
        <ValidationProvider
          tag="div"
          class="form__row_col"
          :rules="{ url: true, max: 255 }"
          v-slot="{ errors }"
        >
          <BaseInput
            placeholder="Url"
            :validateError="errors[0] || serverError.slug"
            v-model="publication.slug"
            :element="publication.slug"
            @input="publication.slug = $event"
          >
            <template #text>
              <span class="form-control__simple-text">
                If available for sale on an external website, paste the link here.
              </span>
              <span class="form-control__simple-text">
                URL:
                <a class="form-control__simple-text" :href="publication.slug" target="_blank">
                  {{ publication.slug }}
                </a>
              </span>
            </template>
          </BaseInput>
        </ValidationProvider>
      </div>
    </ValidationObserver>
  </PopUp>
</template>
<script>
import PopUp from "@/components/PopUp.vue";
import DragAndDropImage from "@/components/DragAndDropImage.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import MultipleAutocompleteInput from "@/components/inputs/MultipleAutocompleteInput.vue";

import { ValidationObserver, ValidationProvider } from "vee-validate";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  props: { item: { type: Object, default: null }, modal: { type: Boolean, default: false } },
  components: {
    PopUp,
    DragAndDropImage,
    BaseButton,
    BaseInput,
    MultipleAutocompleteInput,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      url: process.env.VUE_APP_FRONTEND_URL,
      publication: {
        title: "",
        type: "",
        author: "",
        slug: "",
        artists: [],
      },
      changed: false,
    };
  },
  created() {
    if (this.item != null) {
      this.publication = JSON.parse(JSON.stringify(this.item));
    }
  },
  computed: {
    ...mapGetters("publications", {
      serverError: "getServerError",
      changesLoading: "getChangesLoading",
    }),
    ...mapGetters("artists", {
      artists: "getSearchedArtists",
      artistsLoading: "getChangesLoading",
    }),
  },
  watch: {
    publication: {
      handler: function (val, oldVal) {
        if (this.item != null) {
          if (this.$refs.observer.flags.dirty) {
            this.changed = true;
          }
        } else {
          this.changed = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("artists", ["apiGetSearchedArtists"]),
    hideModal() {
      if (this.changed) {
        this.$swal({
          html: "<h2>Are you sure you want to exit <br/> without saving?</h2>",
          showCancelButton: true,
          cancelButtonText: "Exit without saving",
          confirmButtonText: "Save changes",
        }).then(result => {
          if (result.value) {
            this.$refs.observer.flags.valid ? this.savePublication() : this.showToast();
          } else this.$emit("hideModal");
        });
      } else this.$emit("hideModal");
    },
    showToast() {
      this.$toasted.show(`Please fill in all required fields`, {
        duration: 2000,
        type: "error",
      });
    },
    savePublication() {
      let data = {
        ...this.publication,
        artists: this.publication.artists.map(el => {
          return el.id.toString();
        }),
      };
      if (this.publication.id) {
        this.$emit("updateItem", { data });
      } else {
        this.$emit("createItem", { data });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.publication {
  height: 100%;
  padding: 0 15px;
  overflow-y: auto;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
    @media only screen and (max-width: $xs) {
      flex-direction: column;
      align-items: flex-start;
    }
    &_title {
      font-size: 3.35rem;
      font-weight: 600;
      line-height: 1.2;
      text-transform: capitalize;
      color: $black;
    }
    &_actions {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      @media only screen and (max-width: $xs) {
        justify-content: flex-start;
        width: 100%;
      }
      .button {
        margin-left: 10px;
        @media only screen and (max-width: $xs) {
          margin: 20px 10px 0 0;
        }
      }
    }
  }
}
</style>
